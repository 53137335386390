$primary: darkorange;

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/font/bootstrap-icons.css");
@import '~bootstrap/scss/bootstrap.scss';

a.invisible-link {
  color: inherit;
  text-decoration: none;
}

.products {
  flex-flow: row wrap;

  .product {
    max-width: 250px;
    border: 1px solid darkorange;
    border-radius: 10px;
    padding: 10px;
    margin: 20px 10px 0px 10px;
    position: relative;

    .product-type {
      font-size: 17px;
    }

    .product-name {
      font-size: 20px;
    }

    div.info {
      p.price {
        span.price {
          font-size: 22px;
        }
      }
    }
  }
}
